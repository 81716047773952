<i18n>
	{
		"en": {
			"paymentOption": "Select Payment Option"
		},
		"np": {
			"paymentOption": "भुक्तानी विकल्प छान्नुहोस्"
		}
	}
</i18n>
<template lang="pug">
	div.my-3
		button.btn.btn-primary.btn-block(v-if='kuraakani' @click="bankClicked(kuraakani.shortName)") Nimbuzz Pay
			//b-button.col.m-3(v-b-toggle.allbanks variant='outline-primary' v-if="showBanks") Pay with Bank
		div(id='allbanks' v-if="showBanks")
			.row
				h5.ml-3 {{ $t("paymentOption") }}
			.row
				b-tabs.col-12(justified)
					b-tab(v-for='paymentType in paymentTypes' :title='paymentType' :key="paymentType")
						.row.mt-5
							.col-6.col-lg-4(v-for='bank in getBanksOfCategory(paymentType)' :key='bank._id')
								.card.banks.cursor-pointer(@click='bankClicked(bank.bankCode)')
									.card-body.my-1
											img( class="bank-thumbnail"
												:src="`${FILE_SERVER}/Bank/Icon/`+bank.icon.nameOnServer"
												:alt="bank.name")
									.card-footer
										span {{bank.bankName|| "" }}
</template>
<script>
import { FILE_SERVER } from "@/helpers/general";
export default {
	name: "Bank",
	props: {
		bankClicked: Function,
		banks: Array,
		showBanks: { type: Boolean, default: false },
	},
	data() {
		return {
			kuraakani: "",
			restBanks: [],
			paymentTypes: [],
		};
	},
	computed: {
		FILE_SERVER() {
			return FILE_SERVER;
		},
	},
	mounted() {
		this.restBanks = this.banks;
		this.kuraakani = this.restBanks.find((b) => {
			return b.bankName.indexOf("Kuraakani") != -1;
		});
		this.restBanks = this.restBanks.filter((b) => {
			return b.bankName.indexOf("Kuraakani") === -1;
		});
		this.paymentTypes = [...new Set(this.restBanks.map((b) => b.paymentType))];
	},
	methods: {
		getBanksOfCategory(paymentType) {
			return this.restBanks.filter((b) => b.paymentType === paymentType);
		},
	},
};
</script>

<style scoped>
.bank-thumbnail {
	object-fit: contain;
	width: 100% !important;
}
</style>
